<template>
    <v-layout>
        <b-card>
            Страница не найдена
        </b-card>
    </v-layout>
</template>

<script>

export default {
    name: 'page-error-403',
};
</script>
