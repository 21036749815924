var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [_c("b-card", [_vm._v("\n        Страница не найдена\n    ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }